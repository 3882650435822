export const Passwordpattern=/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#%?^-_/$&*]).{8,}$/
export const Emailpattern=/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
export const Namepattern=/^[a-zA-Z][a-zA-Z][a-zA-Z ]{0,18}$/i
export const Mobilepattern=/^[1-9]\d{9}$/
export const IfscCodepattern=/^[A-Z]{4}0[A-Z0-9]{6}$/
export const upiRegexpattern = /^[\w.-]+@[\w.-]+$/
export const otpPattern=/^[0-9]{4}$/
export const bankAccountPattern  = /^[1-9]\d{5,17}$/


